// libraries
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
// components
import { BREAKPOINT, Container, Col, Row } from 'components/UI/Grid';
import { Button } from 'components/UI/Button';
// constants
import { COLORS } from 'constants/settings';
// static
import imageTop from 'assets/images/error-page/404-image-top.png';
import imageBottom from 'assets/images/error-page/404-image-bottom.png';
import lineDesktop from 'assets/images/error-page/404-line-desktop.svg';
import lineMobile from 'assets/images/error-page/404-line-mobile.svg';

export const Content404 = () => {
    return (
        <StyledContent404 $lineDesktop={lineDesktop} $lineMobile={lineMobile}>
            <Container>
                <StyledWrapper>
                    <StyledInfo xs={12} md={6}>
                        <StyledTitle>¡Ups!</StyledTitle>
                        <StyledDescription
                            dangerouslySetInnerHTML={{ __html: 'No encontramos la página que&nbsp;buscas.' }}
                        />
                        <StyledText
                            dangerouslySetInnerHTML={{
                                __html: 'Es posible que se haya eliminado o movido a otro sitio. ¡Lo sentimos!',
                            }}
                        />
                        <StyledDesktopButtonWrapper>
                            <StyledButton as={Link} variant="prime" to="/">
                                Volver al inicio
                            </StyledButton>
                        </StyledDesktopButtonWrapper>
                    </StyledInfo>
                    <StyledInfo xs={12} md={{ size: 5, offset: 1 }}>
                        <StyledImageWrapper>
                            <StyledImageTop src={imageTop} alt="" />
                            <StyledImageBottom src={imageBottom} alt="" />
                        </StyledImageWrapper>
                        <StyledMobileText>
                            Es posible que se haya eliminado o movido a otro sitio. ¡Lo sentimos!
                        </StyledMobileText>
                        <StyledMobileButtonWrapper>
                            <StyledButton as={Link} variant="prime" to="/">
                                Volver al inicio
                            </StyledButton>
                        </StyledMobileButtonWrapper>
                    </StyledInfo>
                </StyledWrapper>
            </Container>
        </StyledContent404>
    );
};

const StyledContent404 = styled.section`
    position: relative;
    padding-top: 64px;
    background: ${COLORS.secondary};

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        content: '';
    }

    ${({ $lineDesktop, $lineMobile }) => {
        return css`
            &::before {
                background-image: url(${$lineMobile});
                background-repeat: no-repeat;
                background-position: calc(27% - 0px) 130px;

                ${BREAKPOINT.lg} {
                    background-image: url(${$lineDesktop});
                    background-position: calc(50% - 0px) 0;
                }
            }
        `;
    }}

    img {
        max-height: 260px;
        margin: 0 auto;

        ${BREAKPOINT.lg} {
            max-height: 100%;
        }
    }

    ${BREAKPOINT.lg} {
        min-height: 660px;
        padding-top: 80px;
        overflow: hidden;
    }
`;

const StyledWrapper = styled(Row)`
    align-items: center;
    padding: 16px 0 32px;

    ${BREAKPOINT.lg} {
        padding: 40px 0 72px;
    }
`;

const StyledInfo = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${BREAKPOINT.md} {
        align-items: flex-start;
    }

    &:first-child {
        position: relative;
        z-index: 2;
    }
`;

const StyledTitle = styled.h1`
    margin: 0 0 24px;
    font-weight: 900;
    font-size: 34px;
    line-height: 1.3;
    letter-spacing: 1px;
    text-align: center;

    ${BREAKPOINT.md} {
        text-align: left;
    }

    ${BREAKPOINT.lg} {
        margin: 0 0 40px;
        font-size: 48px;
    }
`;

const StyledDescription = styled.p`
    margin: 0 0 24px;
    font-size: 22px;
    line-height: 1.4;
    text-align: center;

    ${BREAKPOINT.md} {
        margin: 0 0 40px;
        font-size: 28px;
        text-align: left;
    }
`;

const StyledText = styled.p`
    display: none;
    margin: 0 0 24px;
    font-size: 18px;
    line-height: 1.55;
    letter-spacing: 0.5px;
    text-align: left;

    ${BREAKPOINT.md} {
        display: block;
        margin: 0 0 40px;
    }

    ${BREAKPOINT.lg} {
        font-size: 24px;
    }
`;

const StyledMobileText = styled.p`
    position: relative;
    z-index: 1;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;

    ${BREAKPOINT.md} {
        display: none;
    }
`;

const StyledDesktopButtonWrapper = styled.div`
    display: none;

    ${BREAKPOINT.lg} {
        display: block;
        width: 100%;
        max-width: 320px;
    }
`;

const StyledMobileButtonWrapper = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;

    ${BREAKPOINT.lg} {
        display: none;
    }
`;

const StyledButton = styled(Button)`
    width: 100%;
    padding: 14px 24px;
    font-size: 14px;

    ${BREAKPOINT.lg} {
        font-size: 16px;
    }
`;

const StyledImageWrapper = styled.div`
    position: relative;
    max-width: 100%;
    height: 260px;
    margin-bottom: 24px;

    ${BREAKPOINT.lg} {
        height: 464px;
        margin-bottom: 0;
    }
`;

const StyledImageTop = styled.img`
    position: relative;
    z-index: 2;
`;

const StyledImageBottom = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);

    ${BREAKPOINT.lg} {
        top: 0;
        left: 0;
        transform: none;
    }
`;
